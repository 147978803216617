<template>
	<div style="background: #f7f7f7; padding: 15px; border-radius: 5px;">
		<h3>{{ question.title }}</h3>

		<div v-html="question.content"></div>
	</div>
</template>

<script type="text/javascript">
export default {
	name: "Header",
	inject: ["question"],
};
//
</script>
"
